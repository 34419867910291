export const transactionsTable = [
    {
        id: 1, 
        name: "Name", 
        amount: "Amount", 
        date: "Date", 
        status: "Status", 
    },
]; 

export const transactionsData = [
    {
        id: 1, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Pending", 
    },
    {
        id: 2, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Pending", 
    },
    {
        id: 3, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Failed", 
    },
    {
        id: 4, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Complete", 
    },
    {
        id: 5, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Complete", 
    },
    {
        id: 6, 
        name: "Equipment Maintenance", 
        amount: "GHS300", 
        date: "09/18/16", 
        status: "Complete", 
    },
];