const PrivacyPolicy = () => {
    return (
        <section className="flex flex-col py-10  min-h-screen h-full w-full px-4 sm:px-14 xl:px-0">
            <div className="w-full h-full flex flex-col gap-14 mx-auto xl:max-w-6.5xl">
                <div className="flex flex-col gap-3 w-full items-center text-coolBlack">
                    <h2 className="text-3xl font-bold sm:text-[2.75rem] text-center leading-relaxed">Privacy Policy – Dove Payments Gateway</h2>
                    <p className="text-base font-normal text-center sm:text-xl">V01 – 9th November 2022</p>
                    <p className="text-base font-normal text-center sm:text-xl">dovepaymentsgh@gmail.com</p>
                </div>
                <div className="flex flex-col items-start leading-loose">
                    <p>NewHealth Technologies Limited, Accra, Ghana</p>
                    <p>Please read, our Privacy Policy carefully as it explains the following:</p>
                    <ul id="privacy" className="pl-4 mt-5">
                        <li>What personal information we collect from you and for which purpose (Section 3 and 4)</li>
                        <li>The measures to safeguard the access to and the security of your Personal Data (Section 5)</li>
                        <li>Your rights to access, rectify, erase your Personal Data and object to the processing of your Personal Data (Section 8).</li>
                    </ul>
                    <p className="font-bold pl-4 mt-5">In order to use the app, you must first acknowledge and agree to our Privacy Policy. You cannot use the app without first accepting our Privacy Policy.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">1. General</h1>
                    <p className="block mb-5">NewHealth Technologies Limited provides the Dove Payments Gateway, an application that enables healthcare providers and their partners/vendors to cater for health care expenses. The Dove payments solution transmits data between the healthcare provider and their preferred service vendor on the app. In addition, the app also analyses and stores data. </p>
                    <p className="block mb-5">NewHealth Technologies Limited  recognizes and understands the importance of the privacy of its users ("users", "You") and wants to respect their desire to store and access personal information in a private and secure manner. This Privacy Policy applies to our application and describes how NewHealth Technologies Limited and Dove Payments manages, stores and utilizes your Personal Data through our web solution.</p>
                    <p className="block mb-5">In order to use the payment solution app, we require you to consent to the collection and processing of your Personal Data before you start using the app. If you do not agree with the terms of this Privacy Policy, you may not use in any manner the app.</p>
                    <p className="block mb-5">NewHealth Technologies Limited is committed to protecting the privacy of all of its users Personal Data and providing a secure, user-controlled environment for the use of Dove payments gateway in accordance with the Privacy Act of 8 December 1992. At the same time, you also share responsibility for maintaining privacy and security for example, by not allowing any third party to use your personal account on Dove payments. NewHealth Technologies Limited asks all users to be responsible for safeguarding any authentication information and to immediately notify NewHealth Technologies Limited of any unauthorized use of your personal Account.</p>
                    <p className="block mb-5">By accepting this Privacy Policy, you acknowledge and agree that you were adequately informed of the functioning and objectives of the payment web Application.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">2. Definitions</h1>
                    <ul id="privacy" className="pl-4 mt-5">
                        <li>"Account" means your account registered through the registration process on the Dove payments gateway.</li>
                        <li>"Application" “App” means the (i) Dove Payments Gateway/ Application</li>
                        <li>"Dove Payments  Application" means the web application (i) which the user has to visit and create a facility or vendor account(ii) is intended to enable providers cater to healthcare payments</li>
                        <li>"Dove Platform" means the platform on which the Dove web application is connected and functions from. Depending on the user profile different application functions can be made available</li>
                        <li>"Third Parties" means any natural or legal person or entity other than dove payments and NewHealth Technologies Limited</li>
                        <li>"Website" means the website available at www.dovepaymentsgh.com</li>
                    </ul>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">3. Information we collect</h1>
                    <p className="block mb-5">We may collect several types of personal information from the users of our app, and store it securely our server.</p>
                    <ul id="privacy" className="pl-4 my-5">
                        <li>Contact information (e.g. your name, e-mail address, and mobile number)</li>
                        <li>The profile data provided by you on your account such as your name and profile picture</li>
                        <li>Information in helpdesk support inquiries</li>
                        <li>Patient demographics such as date of birth and gender</li>
                        <li>Information about your device, such as its model, unique device identifier and operating system version</li>
                    </ul>
                    <p className="block mb-5">Hereinafter collectively "Personal Data".</p>
                    <p className="block mb-5">We shall store your Personal Data on our servers on Heroku or AWS.</p>
                    <p className="block mb-5">We would like to draw your attention to the fact that Personal Data consisting of patient medical data is also protected by medical secrecy rules.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">4. How do we use your Personal Data</h1>
                    <p className="block mb-5">NewHealth Technologies Limited (i) collects, (ii) uses, (iii) maintains and (iv) may share your Personal Data with healthcare providers for all purposes necessary to ensure the proper functioning and operation of the User accounts and/or the proper functioning of the app. These purposes may include (collectively the "Purpose"):</p>
                    <ul id="privacy" className="pl-4 my-5">
                        <li>Creating and managing your Account to use the Application</li>
                        <li>Providing information and allowing the Users access to the app</li>
                        <li>Contacting the Users by phone, email, text message or push notifications (if they are enabled) to verify your account (ii) for information and operational purposes such as account management, instructions, alerts, reminders, customer service, system maintenance and others</li>
                        <li>The operation, evaluation and improvement of the payment solution (including the development of additional products and/or services, enhancing and improving the current app, analysis of our app, quality control activities and performing internal business functions such as accounting and auditing)</li>
                        <li>Protecting against, identify and prevent fraud and other unlawful activity, claims and other liabilities</li>
                        <li>Complying with and enforcing any applicable legal obligations with respect to our Terms of Use and Privacy Policy.</li>
                    </ul>
                    <p className="block mb-5">Furthermore, you agree that NewHealth Technologies Limited is free to access, retain, and disclose Personal Data in the following events:</p>
                    <ul id="privacy" className="pl-4 my-5">
                        <li>In order to be in compliance with any applicable legislation or regulations</li>
                        <li>If a law enforcement authority or other government official requests</li>
                        <li>To enforce the NewHealth Technologies Limited Terms of Use or Privacy Policy</li>
                        <li>The investigation of any suspected or actual fraudulent or illegal activity</li>
                        <li>To protect your or our safety and/or rights</li>
                    </ul>
                    <p className="block mb-5">By using the Application, you acknowledge and explicitly agree that we may disclose certain information to vendors and service providers who help us provide the app. Examples of these vendors and service providers include entities that process credit or debit card payments and provide analytics and web hosting services.</p>
                    <p className="block mb-5">You furthermore understand and agree that the technical processing and transmission of the app, including your Personal Data, may involve (i) transmissions over various networks; and modifications to conform and adapt to technical requirements of connecting networks, or devices.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">5. Security</h1>
                    <p className="block mb-5">NewHealth Technologies Limited shall take appropriate administrative, technical and organizational measures against unauthorized or unlawful processing of any Personal Data or its accidental loss, destruction or damage, access, disclosure or use. Upon written request, NewHealth Technologies Limited can provide you with a list of people at NewHealth Technologies Limited that may have access to your Personal Data. These people have entered into confidentiality agreements prior to having been granted access to your Personal Data.</p>
                    <p className="block mb-5">In the event of and following discovery or notification of a breach of the security of the Personal Data, or access by an unauthorized person, NewHealth Technologies Limited is required by law to notify the user if the breach is likely to affect your privacy.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">6. Cookies</h1>
                    <p className="block mb-5">When using the Dove Payments Gateway, we may collect certain information by automated means, such as cookies (small text files stored in your browser) and similar technologies, including web application identifiers, to improve your experience of the app, increase security, measure use and effectiveness of the app, identify and resolve issues and marketing purposes. We may also use cookies and other automated means for purposes such as managing our app and other aspects of the commercialization of our business.</p>
                    <p className="block mb-5">The information we collect in this manner includes IP address, browser characteristics, device characteristics, operating system version, information on actions taken on our app (such as usage, activity logs and click-throughs), and dates and times of visits. Log data is deleted on a regular basis.</p>
                    <p className="block mb-5">You can control cookies through your browser settings and other tools. Your device may offer you control over the use of cookies or other technologies when you use the app. For example, you may be able to set your device or browser to disable, clear, reset or block the use of cookies or similar technologies. Please note, however, that without cookies the app may not work properly or you may not be able to use all of their features.</p>
                    <p className="block mb-5">Third parties, such as Google analytics, may also use cookies to collect information about your activities on our app to understand and continue improving the performance of our app. Please check the relevant third-party website for more information about their use of cookies. Where this site allows such cookies to be set or you access other websites.</p>
                    <p className="font-bold block mb-5">By continuing to use our web payment application, you consent to the placement of cookies and other automated means in your browser and device in accordance with this Privacy Policy.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">7. Disclosure to Third Parties</h1>
                    <p className="block mb-5">In the event of full or partial merger with, or acquisition of all or part of NewHealth Technologies Limited, we may transfer your Personal Data to a Third Party. In such event, NewHealth Technologies Limited shall impose this Third Party to use any Personal Data strictly consistent with this Privacy Policy.</p>
                    <p className="block mb-5">Other than as set out in this Privacy Policy, we shall not sell or otherwise disclose your Personal Data to Third Parties without obtaining your prior explicit consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.</p>
                    <p className="block mb-5">We may also share Personal Data with Third Party service providers who help us to provide, understand, commercialize and improve Dove Payments gateway. We do not authorize these Third Party service providers to use or disclose your Personal Data except as strictly necessary to perform any services under our supervision or to comply with applicable legislation. We seek to provide any such Third Party service provider with only the Personal Data they need to perform their specific function.</p>
                    <p className="block mb-5">In any event, such Third Party service providers shall be obliged to treat your Personal Data in accordance with this Privacy Policy. However, NewHealth Technologies Limited cannot be held liable for any damages, whether direct or indirect, that may result from the misuse of your Personal Data by such Third Party service providers.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">8. Your Rights</h1>
                    <p className="block mb-5"><span className="font-bold">Right of access</span> If you are concerned or have any questions about your Personal Data, you have the right to request access to the Personal Data which we hold or process about you. We will then provide you with information about the data that are being processed and on the source of those data.</p>
                    <p className="block mb-5"><span className="font-bold">Right of rectification and right of erasure</span> You have the right to request us free of charge to correct, erase or block any inaccuracies in your Personal Data if such Personal Data would be incomplete, inaccurate or processed unlawfully.</p>
                    <p className="block mb-5"><span className="font-bold">Right to not be subject to automated individual decision making</span> You have the right to request us not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</p>
                    <p className="block mb-5"><span className="font-bold">Right to object to data processing</span> Without prejudice to the termination provisions of the Terms of Use, you may withdraw at any time your consent to the processing of your Personal Data by NewHealth Technologies Limited by permanently removing the Application and notifying us in writing thereof via dovepaymentsgh@gmail.com . Upon receipt of this notification, NewHealth Technologies Limited shall promptly stop any processing of your Personal Data. You may also ask us to stop using your Personal Data for direct marketing purposes.</p>
                    <p className="block mb-5">To exercise your abovementioned rights or any other questions concerning this Privacy Policy, please contact us by e-mail or in writing on the address below. You should add a copy of your identity card or other proof of your identity to your request. NewHealth Technologies Limited will undertake the necessary action without undue delay and provide information on action taken (or the absence of any such action) within 30 days.</p>
                    <p className="block mb-5">You may also lodge a complaint with a supervisory authority in case of breach of the applicable privacy laws by NewHealth Technologies Limited.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">9. Data retention and deletion</h1>
                    <p className="block mb-5">We may retain information regarding you and your use of the app, including Personal Data, for as long as reasonably needed to provide you with the best service possible as described in this Privacy Policy.</p>
                    <p className="block mb-5">Please note that we retain your Personal Data even if you temporarily stop using the Application until you permanently delete your account and notify us thereof. Upon notification, we may retain and continue to use and disclose your Personal Data to Third Parties exclusively on a fully anonymized basis.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">10. Third Party websites or applications</h1>
                    <p className="block mb-5">You acknowledge and agree that certain content or services provided by Third Parties may be made available to you through the app. You agree that such linked content or services may have their own privacy policies for which NewHealth Technologies Limited cannot be held responsible. NewHealth Technologies Limited does not in any way review or endorse the privacy practices of such Third Parties.</p>
                </div>
                <div className="leading-loose">
                    <h1 className="font-bold text-[22px]">11. Updates or changes to our Privacy Policy</h1>
                    <p className="block mb-1">Occasionally, we may change or update this Privacy Policy to allow us to use or share your previously collected Personal Data for other purposes. If NewHealth Technologies Limited would use your Personal Data in a manner materially different from that stated at the time of the collection, we will provide you with a notice on our Website  indicating that the Privacy Policy has been changed or updated and request you to agree with the updated or changed Privacy Policy.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Manager</h4>
                    <p className="block">NewHealth Technologies Limited Privacy Team</p>
                    <p className="block mb-2">Accra, Ghana</p>
                    <p className="block mb">dovepaymentsgh@gmail.com</p>
                    <p className="block mb">https://www.dovepayments.com</p>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy