export const adminTable = [
    {
        id: 1, 
        avatar: "Avatar",
        name: "Name", 
        email: "Email", 
        password: "Password", 
        status: "Status", 
    },
]; 

export const adminData = [
    {
        id: 1, 
        profile: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 2, 
        profile: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 3, 
        profile: "https://images.unsplash.com/photo-1540845511934-7721dd7adec3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 4, 
        profile: "https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&h=160&w=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 5, 
        profile: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 6, 
        profile: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Inactive", 
    },
    {
        id: 7, 
        profile: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Active", 
    },
    {
        id: 8, 
        profile: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80", 
        name: "Cameron Williamson", 
        email: "nathan.roberts@example.com", 
        password: "ahmadyani1234", 
        status: "Inactive", 
    },
];