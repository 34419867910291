import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Rewrite, ToggleOn, ToggleOff } from "../../assets";
import { Sidebar } from "../../components"; 
import { notifications } from "../../constants/notifications"; 

const Settings = ({ user, setUser }) => {
    // useState
    const [profileFormData, setProfileFormData] = useState({
        firstName: "",
        lastName: "",
        username: "",
        organization: "",
    });

    const [personalFormData, setPersonalFormData] = useState({
        email: "", 
        idNumber: "",
        phone: "", 
        country: "",
    });

    const [passwordFormData, setPasswordFormData] = useState({
        password: "",
        newPassword: "", 
        confirmNewPassword: "",
    });

    // destructure profile/personal/password
    const { firstName, lastName, username, organization } = profileFormData; 
    const { email, idNumber, phone, country } = personalFormData; 
    const { password, newPassword, confirmNewPassword } = passwordFormData; 

    // useNavigate
    const navigate = useNavigate(); 

    // useEffect
    useEffect(() => {
        if(!user) {
            navigate("/"); 
        }
    }, [user, navigate]); 
    
    // handleChange profile/personal/password
    const handleChangeProfile = (e) => {
        setProfileFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        })); 
    };

    const handleChangePersonal = (e) => {
        setPersonalFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        })); 
    };

    const handleChangePassword = (e) => {
        setPasswordFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        })); 
    };

    // handleSubmit profile/personal/password
    const handleSubmitProfile = (e) => {
        e.preventDefault(); 
        console.log("Update Profile"); 
    }; 
    
    const handleSubmitPersonal = (e) => {
        e.preventDefault(); 
        console.log("Update Personal"); 
    }; 
    
    const handleSubmitPassword = (e) => {
        e.preventDefault(); 
        console.log("Update Password"); 
    }; 

    return (
        <section className="flex w-full h-full">
            <Sidebar user={user} setUser={setUser} />
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="bg-white rounded-xl p-5 md:p-10 w-full">
                        <div className="flex items-start lg:mb-4 mb-2 flex-col lg:flex-row">
                            <div className="mb-4 lg:mb-0">
                                <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Settings</h3>
                                <p className="font-poppins text-coolBlack/[0.6]">This information will be displayed publicly so be careful what you input.</p>
                            </div>
                        </div>

                        {/* FORM profile */}
                        <div className="w-full h-full">
                            <form className="flex flex-col rounded-[12px] bg-white mt-10 " onSubmit={handleSubmitProfile}>
                                <div className="flex flex-wrap flex-col lg:flex-row w-full">
                                    <div className="flex w-full px-5 mb-3">
                                        <h2 className="font-poppins font-bold text-[20px] text-coolBlack">Profile</h2>
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">First Name</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon" />
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            value={firstName}
                                            onChange={handleChangeProfile}
                                            placeholder="Olukoya"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Last Name</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            value={lastName}
                                            onChange={handleChangeProfile}
                                            placeholder="Michaels"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Username</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="username"
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={handleChangeProfile}
                                            placeholder="lazymeercat616"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Organization Name</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="organization"
                                            type="text"
                                            name="organization"
                                            value={organization}
                                            onChange={handleChangeProfile}
                                            placeholder="Best Medical Center"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-6 pb-3 px-5 justify-end">
                                        <div className="md:mb-3">
                                            <button className="font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue px-5 py-2 rounded-[40px] ">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* FORM personal */}
                        <div className="w-full h-full">
                            <form className="flex flex-col rounded-[12px] bg-white mt-10 " onSubmit={handleSubmitPersonal}>
                                <div className="flex flex-wrap flex-col lg:flex-row w-full">
                                    <div className="flex w-full px-5 mb-3">
                                        <h2 className="font-poppins font-bold text-[20px] text-coolBlack">Personal Info</h2>
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Email Address</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="email"
                                            type="text"
                                            name="email"
                                            value={email}
                                            onChange={handleChangePersonal}
                                            placeholder="kenzi.lawson@example.com"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">ID Number</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="idNumber"
                                            type="text"
                                            name="idNumber"
                                            value={idNumber}
                                            onChange={handleChangePersonal}
                                            placeholder="1526DS52"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Phone Number</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="phone"
                                            type="text"
                                            name="phone"
                                            value={phone}
                                            onChange={handleChangePersonal}
                                            placeholder="(201) 555-0124"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Country</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="country"
                                            type="text"
                                            name="country"
                                            value={country}
                                            onChange={handleChangePersonal}
                                            placeholder="Ghana"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-6 pb-3 px-5 justify-end">
                                        <div className="md:mb-3">
                                            <button className="font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue px-5 py-2 rounded-[40px] ">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* FORM password */}
                        <div className="w-full h-full">
                            <form className="flex flex-col rounded-[12px] bg-white mt-10 " onSubmit={handleSubmitPassword}>
                                <div className="flex flex-wrap flex-col lg:flex-row w-full">
                                    <div className="flex w-full px-5 mb-3">
                                        <h2 className="font-poppins font-bold text-[20px] text-coolBlack">Password</h2>
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Old Password</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon" />
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="password"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={handleChangePassword}
                                            placeholder="Enter old password"
                                            autoComplete="on"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">New Password</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="newPassword"
                                            type="password"
                                            name="newPassword"
                                            value={newPassword}
                                            onChange={handleChangePassword}
                                            placeholder="Enter new password"
                                            autoComplete="on"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Confirm Password</label>
                                        <Rewrite className="hidden md:block absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack/[0.6] text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="confirmNewPassword"
                                            type="password"
                                            name="confirmNewPassword"
                                            value={confirmNewPassword}
                                            onChange={handleChangePassword}
                                            placeholder="Confirm new password"
                                            autoComplete="on"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-6 pb-3 px-5 justify-end">
                                        <div className="md:mb-3">
                                            <button className="font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue px-5 py-2 rounded-[40px] ">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* TODO NOTIFICATIONS */}
                        <div className="w-full h-full">
                            <form className="flex flex-col rounded-[12px] bg-white mt-10 " onSubmit={handleSubmitPassword}>
                                <div className="flex flex-wrap flex-col lg:flex-row w-full">
                                    <div className="flex w-full px-5 mb-3">
                                        <h2 className="font-poppins font-bold text-[20px] text-coolBlack">Notifications</h2>
                                    </div>
                                    {notifications.map((notification) => (
                                        <div key={notification.id} className="flex items-center justify-between py-3 px-5 lg:w-1/2">
                                            <p className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">{notification.title}</p>
                                            {notification.status === "off" ? <ToggleOff /> : <ToggleOn />}
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Settings