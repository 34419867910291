const TermsAndConditions = () => {
    return (
        <section className="flex flex-col py-10  min-h-screen h-full w-full px-4 sm:px-14 xl:px-0">
            <div className="w-full h-full flex flex-col gap-14 mx-auto xl:max-w-6.5xl">
                <div className="flex flex-col w-full items-center text-coolBlack">
                    <h2 className="text-3xl font-bold sm:text-[2.75rem] text-center leading-relaxed">Terms and Conditions – Dove Payments Gateway</h2>
                </div>
                <div className="flex flex-col items-start leading-loose">
                    <p>Please read these terms and conditions ("terms and conditions", "terms") carefully before using the Dove Payments Gateway operated by NewHealth Technologies Limited.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Conditions of use</h4>
                    <p className="block">By using the Dove Payments, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to exit the payment system accordingly. NewHealth Technologies only grants use and access of this payment system, its products, and its services to those who have accepted its terms.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Privacy policy</h4>
                    <p className="block">Before you continue using our website/payment solution, we advise you to read our privacy policy [dovepayments.co/privacy] regarding our user data collection. It will help you better understand our practices.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Age restriction</h4>
                    <p className="block">You must be at least 18 (eighteen) years of age before you can use this payment solution. By using this system, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. NewHealth Technologies assumes no responsibility for liabilities related to age misrepresentation. </p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Intellectual property</h4>
                    <p className="block">You agree that all materials, products, and services provided on this payment application are the property of Newhealth Technologies, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute Newhealth Technologies’s intellectual property in any way, including electronic, digital, or new trademark registrations.</p>
                    <p className="block">You grant NewHealth Technologies a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">User accounts</h4>
                    <p className="block">As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</p>
                    <p className="block">If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.</p>
                    <p className="block">We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Applicable law</h4>
                    <p className="block">By visiting this website, you agree that the laws of Ghana, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between NewHealth Technologies and you, or its business partners and associates.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Disputes</h4>
                    <p className="block">Any dispute related in any way to your visit to this website or payment services we offer shall be arbitrated by state or federal court Ghana and you consent to exclusive jurisdiction and venue of such courts.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Indemnification</h4>
                    <p className="block">You agree to indemnify NewHealth Technologies and its affiliates and hold NewHealth Technologies harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.</p>
                </div>
                <div className="leading-loose">
                    <h4 className="font-bold text-[18px]">Limitation on liability</h4>
                    <p className="block">NewHealth Technologies is not liable for any damages that may occur to you as a result of your misuse of our payment solution and system.</p>
                    <p className="block">NewHealth Technologies reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between NewHealth Technologies and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</p>
                </div>
            </div>
        </section>
    )
}

export default TermsAndConditions