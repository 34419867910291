const Partners = () => {
    return (
        <section>
            <div className="bg-coolBlack flex flex-col items-center justify-center w-full ">
                <h2 className="font-poppins font-bold -tracking-[2%] mt-[40px] mb-[10px] text-white xl:text-[44px] text-center">Our Partners</h2>
                <p className="font-poppins mt-[10px] mb-[40px] text-ghostWhite xl:text-[20px] text-center">Our payments go through the most secure and reliable partners you can find</p>
            </div>
        </section>
    )
};

export default Partners;