import { useEffect, useState } from "react"; 
import { profile, Rewrite } from "../../assets";
import { MemberCard, Sidebar } from "../../components"; 
import { useNavigate } from "react-router-dom";

const Profile = ({ user, setUser }) => {
    // useState
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        username: "",
        organization: "",
        idNumber: "",
        email: "",
        country: "",
        phone: "", 
    });

    // destructure formData
    const { firstName, lastName, username, organization, idNumber, email, country, phone } = formData; 

    // useNavigate
    const navigate = useNavigate();

    // useEffect
    useEffect(() => {
        if(user === "") {
            navigate("/"); 
        }; 
    }, [user, navigate]); 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        }));
    };

    // handleSubmit 
    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log(firstName, lastName, username, organization, idNumber, email, country, phone); 
    }; 

    return (
        <section className="flex w-full h-full">
            <Sidebar user={user} setUser={setUser} />
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="bg-cultured h-full w-full">
                        <div className="">
                            <h1 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">My Profile</h1>
                        </div>
                        <div className="lg:flex gap-2">
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <div className="flex flex-1.5 flex-col my-5">
                                    <div className="bg-white rounded-t-[28px] flex-col p-6 drop-shadow-xl min-w-[320px]">
                                        <div className="flex items-center justify-start">
                                            <img className="w-[160px] h-[160px] rounded-[50%]" src={profile} alt="profile" />
                                        </div>
                                    </div>
                                    <div className="bg-coolBlack rounded-b-[28px] flex-col p-6 drop-shadow-xl">
                                        <h1 className="font-poppins font-normal text-white">Olukoya Michaels</h1>
                                        <p className="font-poppins font-normal text-white">Best Medical Center</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:justify-end">
                                <MemberCard />
                            </div>
                        </div>
                        <div className="w-full h-full">
                            <form className="flex flex-col rounded-[12px] bg-white mt-10 drop-shadow-xl" onSubmit={handleSubmit}>
                                <div className="flex flex-wrap flex-col lg:flex-row w-full">
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">First Name</label>
                                        <Rewrite className="absolute top-[54px] right-10 cursor-pointer" id="icon" />
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            value={firstName}
                                            onChange={handleChange}
                                            placeholder="Olukoya"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Last Name</label>
                                        <Rewrite className="absolute top-[54px] right-10 cursor-pointer" id="icon"/>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            value={lastName}
                                            onChange={handleChange}
                                            placeholder="Michaels"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Username</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="username"
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={handleChange}
                                            placeholder="lazymeercat616"
                                        />
                                        {/* {username.length === 0 ? (<p className="text-red-500 text-xs italic">Please fill out this field.</p>) : ""} */}
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Organization Name</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="organization"
                                            type="text"
                                            name="organization"
                                            value={organization}
                                            onChange={handleChange}
                                            placeholder="Best Medical Center"
                                        />
                                        {/* {organization.length === 0 ? (<p className="text-red-500 text-xs italic">Please fill out this field.</p>) : ""} */}
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">ID Number</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="idNumber"
                                            type="text"
                                            name="idNumber"
                                            value={idNumber}
                                            onChange={handleChange}
                                            placeholder="1526DS52"
                                        />
                                        {/* {idNumber.length === 0 ? (<p className="text-red-500 text-xs italic">Please fill out this field.</p>) : ""} */}
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Email</label>
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="email"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            placeholder="kenzi.lawson@example.com"
                                        />
                                    </div>
                                    <div className="flex flex-col py-3 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack text-[16px] xl:-text[20px] mb-2">Country</label>
                                        <Rewrite className="absolute top-[54px] right-10 cursor-pointer" id="icon" />
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="country"
                                            type="text"
                                            name="country"
                                            value={country}
                                            onChange={handleChange}
                                            placeholder="Ghana"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-3 pb-1 px-5 lg:w-1/2 relative">
                                        <label htmlFor="amount" className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] xl:-text[20px] mb-2">Phone Number</label>
                                        <Rewrite className="absolute top-[54px] right-10 cursor-pointer" id="icon" />
                                        <input 
                                            className="font-poppins text-coolBlack placeholder-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-2 px-5 py-2"
                                            id="phone"
                                            type="text"
                                            name="phone"
                                            value={phone}
                                            onChange={handleChange}
                                            placeholder="(201) 555-0124"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-6 pb-3 px-5 items-end">
                                        <div className="md:mb-3">
                                            <button className="font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue px-5 py-2 rounded-[40px] drop-shadow-xl">Update Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Profile