import { dove1 } from "../../assets"; 

const MemberCard = () => {
    return (
        <div className="flex bg-heavenlyGray rounded-[28px] p-6 drop-shadow-xl min-h-[200px] w-[350px]">
            <div className="flex flex-col flex-1 justify-between items-start">
                <div>
                    <img className="w-[122px] h-[43px] object-contain" src={dove1} alt="dove logo" />
                </div>
                <div>
                    <h1 className="font-poppins font-normal text-coolBlack text-[20px] leading-[30px]">Olukoya Michaels</h1>
                    <p className="font-poppins font-light text-coolBlack text-[14px] leading-[21px]">Best Medical Center</p>
                </div>
            </div>
            <div className="flex flex-col flex-1 justify-between items-end">
                <div className="text-right">
                    <p className="font-poppins font-semibold leading-[24px] text-coolBlack/[0.8]">Current Balance</p>
                    <h3 className="font-poppins font-bold leading-[36px] text-coolBlack text-[24px]">$235.43</h3>
                </div>
                <div>
                    <button className="font-inter font-semibold bg-coolBlack text-paleRobinEggBlue px-5 py-2 rounded drop-shadow-xl">Deposit</button>
                </div>    
            </div>
        </div>
    )
}

export default MemberCard