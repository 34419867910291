import { useState } from "react"; 
import { faqs } from "../../constants/faqs"; 
import { ArrowDown, ArrowUpBlu } from "../../assets/svgs"; 

const FAQItem = () => {
    const [selected, setSelected] = useState(null); 

    const toggle = (index) => {
        if(selected === index) {
            return setSelected(null); 
        }; 
        setSelected(index); 
    }; 

    return (
        <div className="flex flex-col gap-5 flex-1">
            {faqs.map((item, index) => (
                <div key={index} className={selected === index
                    ? "flex flex-col py-[1.0635rem] px-8 bg-[#FAFAFF] transition-all  border-b-[0.3px] border-solid border-[#98A1B0] "
                    : "flex flex-col gap-3 border-b-[0.3px] border-solid border-[#98A1B0] transition-all pb-2 md:pb-3"}
                >
                    <div
                        className="flex items-center justify-between cursor-pointer text-poppins"
                        onClick={() => toggle(index)}
                    >
                        <h4 className={selected === index
                                ? "text-lg text-coolBlack  font-bold"
                                : "text-white text-lg "}
                        >
                            {item.title}
                        </h4>
                        <div>
                            {selected === index ? <ArrowUpBlu /> : <ArrowDown />}
                        </div>
                    </div>
                    <p className={selected === index
                        ? "font-normal text-lg text-coolBlack max-h-[999px] transition-[height]"
                        : "max-h-[0] overflow-hidden transition-[height]"}
                    >
                        {item.text}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default FAQItem