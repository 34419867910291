import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar"; 
import { transactionsTable, transactionsData } from "../../constants/transactions"; 

const Transactions = ({ user, setUser }) => {
    // useNavigate
    const navigate = useNavigate(); 

    // useEffect
    useEffect(() => {
        if(!user) {
            navigate("/"); 
        };
    }, [user, navigate]);

    return (
        <section className="flex w-full min-h-screen">
            <Sidebar user={user} setUser={setUser} />
            <div className="bg-cultured p-10 h-full w-full xl:px-32">
                <div className="flex items-center justify-between mb-6 flex-col lg:flex-row">
                    <div className="mb-4 lg:mb-0">
                        <h1 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Transactions</h1>
                    </div>
                </div>
                <div>
                    <div className="overflow-auto rounded-lg shadow hidden lg:block -mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
                        <table className="w-full">
                            <thead className="bg-coolBlack">
                                {transactionsTable.map((transaction) => (
                                    <tr key={transaction.id} className="mb-2 lg:mb-0">
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{transaction.name}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{transaction.amount}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{transaction.date}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{transaction.status}</th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {transactionsData.map((transaction, index) => (
                                    <tr key={transaction.id} className={`${index % 2 === 0 ? "bg-coolBlack/[0.6]" : "bg-paleRobinEggBlue/[0.6]"}`}>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">{transaction.name}</td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">{transaction.amount}</td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">{transaction.date}</td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">
                                            <span className={`p-1.5 text-xs font-medium uppercase tracking-wider text-coolBlack  rounded-lg bg-opacity-80 ${index % 2 === 0 ? "bg-coolBlack text-white" : "bg-red-500 text-white"}`}>{transaction.status}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:hidden">
                        {transactionsData.map((transaction) => (                            
                            <div key={transaction.id} className="bg-white space-y-3 p-4 rounded-lg shadow-lg">
                                <div className="flex items-center justify-between space-x-2 text-sm w-full">
                                    <div className="text-gray-500">{transaction.name}</div>
                                        <div>
                                            <span
                                            className={`p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 ${transaction.status === "Pending" && "bg-blue-200"} ${transaction.status === "Failed" && "bg-red-500"} ${transaction.status === "Complete" && "bg-green-200"} rounded-lg bg-opacity-50`}>{transaction.status}</span>
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium text-gray-700">
                                        {transaction.amount}
                                    </div>
                                    <div className="text-sm font-normal text-black">
                                        {transaction.date}    
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Transactions