import { useState } from "react";
import { Link } from "react-router-dom";
import { dove1, closeBlack , menuWhite, arrowRight, profile, Bell } from "../../assets";
import { navLinks, navLinksUser, navLinksMobileUserWhite } from "../../constants/nav"; 

const Navbar = ({ user, setUser }) => {
    // useState
    const [toggle, setToggle] = useState(false);

    const handleUserLogout = () => {
        setUser((prev) => !prev); 
        setToggle((prev) => !prev); 
    }; 

    return (
        <nav className={`sticky top-0 ${!toggle ? "bg-coolBlack" : "bg-white"} md:bg-coolBlack w-full flex px-4 h-[65px] sm:px-14 xl:px-0 z-10 transition-all`}>
            <div className="flex justify-between items-center xl:max-w-6.5xl m-auto w-full">
                <Link to="/">
                    <img
                        className="object-contain w-[122px] h-[43px]"
                        src={dove1}
                        alt="dove logo"
                    />
                </Link>
                <ul className="list-none md:flex hidden justify-start items-center flex-1 pl-9 gap-5">
                    {user && navLinksUser.map((nav) => (
                        <li
                            key={nav.id}
                            className="font-inter font-normal cursor-pointer text-[15px] text-white"
                        >
                            <Link to={nav.url}>{nav.title}</Link>
                        </li>
                    ))}
                    {!user && navLinks.map((nav) => (
                        <li
                            key={nav.id}
                            className="font-inter font-normal cursor-pointer text-[15px] text-white"
                        >
                            <Link to={nav.url}>{nav.title}</Link>
                        </li>
                    ))}
                </ul>
                <ul className="list-none md:flex hidden justify-end items-center flex-1 gap-5">
                    {user ? (
                        <>
                            <li
                                className={`font-inter font-normal cursor-pointer text-[13px] text-white`}
                            >
                                <Bell id="icon" className="cursor-pointer"/>
                            </li>
                            <div className="flex items-center">
                                <img className="w-[50px] h-[50px] rounded-[50%] mr-5" src={profile} alt="profile" />
                                <Link to="/profile">
                                    <p className="font-poppins font-semibold text-[14px] text-white">Olukoya Michaels</p>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <li
                                className={`font-inter font-normal cursor-pointer text-[13px] text-white`}
                            >
                                <Link to="/login">Login</Link>
                            </li>
                            <div className="bg-paleRobinEggBlue px-2 py-2 rounded">
                                <button
                                    className={`font-inter font-normal cursor-pointer text-[13px] mr-0 text-coolBlack`}
                                >
                                    <Link to="/register">Create Account</Link>
                                </button>
                            </div>
                        </>
                    )}
                </ul>
                {/* Mobile Navbar */}
                <div className="md:hidden flex flex-1 justify-end items-center">
                    <img
                        src={toggle ? closeBlack : menuWhite}
                        alt="hamburger"
                        className="w-[18px] h-[18px] object-contain cursor-pointer"
                        onClick={() => setToggle((prev) => !prev)}
                    />
                    <div className={`${toggle ? "flex" : "hidden"} p-6 bg-coolBlack fixed top-[65px] left-0 h-screen w-full sidebar z-10 px-14 py-6 sm:py-10 sm:px-14 xl:px-0 overflow-auto`}>
                        <ul className="flex flex-col gap-5 flex-1">
                            {user && (
                                <div>
                                    <div className="flex flex-col justify-center mb-3 flex-1">
                                        <img className="w-[75px] h-[75px] rounded-[50%] mb-2" src={profile} alt="profile" />
                                        <div className="flex flex-1 justify-between items-center">
                                            <h3 className="font-poppins font-semibold text-[18px] leading-[24px] text-white">Olukoya Michaels</h3>
                                            <button className="ml-4 px-4 py-1 bg-paleRobinEggBlue rounded text-[14px]">
                                                <Link to="/profile" onClick={() => setToggle((prev) => !prev)}>Profile</Link>
                                            </button>
                                        </div>
                                        <p className="font-poppins font-light text-[14px] leading-[21px] text-paleRobinEggBlue">Best Medical Center</p>
                                    </div>
                                    <hr className="w-full p-0 m-0 border-paleRobinEggBlue/[0.8] mb-3" />
                                    <div className="flex flex-col justify-center mb-3">
                                        <p className="font-poppins font-light text-[14px] leading-[21px] text-paleRobinEggBlue">Balance</p>
                                        <div className="flex flex-1 justify-between items-center">
                                            <h3 className="font-poppins font-semibold text-[18px] leading-[24px] text-white">GHS 300</h3>
                                            <button className="ml-4 px-4 py-1 bg-paleRobinEggBlue rounded text-[14px]">
                                                <Link to="/deposit" onClick={() => setToggle((prev) => !prev)}>Deposit</Link>
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="w-full p-0 m-0 border-paleRobinEggBlue/[0.8] mb-3" />
                                </div>
                            )}
                            {user && navLinksMobileUserWhite.map((nav) => (
                                <li
                                    key={nav.id}
                                    className="font-inter font-normal cursor-pointer text-[13px] text-white"
                                >
                                    <Link className="inline-block w-full" to={nav.url} onClick={() => setToggle((prev) => !prev)}>
                                        <div className="flex items-center gap-5">
                                            <div className="flex items-center" id="logo mobile">{nav.logo}</div>
                                            <div className="font-poppins text-[16px]">{nav.title}</div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                            <hr className="w-full p-0 m-0 border-paleRobinEggBlue/[0.8]" />
                            {user && (
                                <div>
                                    <div className="flex flex-col justify-center mb-3">
                                        <h3 className="font-poppins font-light text-[14px] leading-[21px] text-paleRobinEggBlue">Help</h3>
                                    </div>
                                    <div className="flex flex-col justify-center mb-3">
                                        <h3 className="font-poppins font-light text-[14px] leading-[21px] text-paleRobinEggBlue">
                                            <Link to="/" onClick={handleUserLogout}>Log Out</Link>
                                        </h3>
                                    </div>
                                </div>
                            )}
                            {!user && navLinks.map((nav) => (
                                <li
                                    key={nav.id}
                                    className="font-poppins font-normal cursor-pointer text-[18px] mb-4 text-white"
                                >
                                    <Link className="inline-block w-full" to={nav.url} onClick={() => setToggle((prev) => !prev)}>
                                        {nav.title}
                                        <img className="w-[18px] h-[18px] object-contain float-right" src={arrowRight} alt="arrow"/>
                                    </Link>
                                </li>
                            ))}
                            {!user && (
                                <>
                                    <li className="font-poppins font-normal cursor-pointer text-[18px] mb-4 text-white">
                                        <Link className="inline-block w-full" to="/login" onClick={() => setToggle((prev) => !prev)}>Login</Link>
                                    </li>
                                    <div className="bg-paleRobinEggBlue px-2 py-2 rounded">
                                        <button className="font-poppins font-normal cursor-pointer text-[20px] mr-0 text-coolBlack inline-block w-full text-center">
                                            <Link className="inline-block w-full" to="/register" onClick={() => setToggle((prev) => !prev)}>Create Account</Link>
                                        </button>
                                    </div>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar