import { benefits } from "../../constants/benefits"; 
import { BenefitCard } from "../../components"; 

const Benefits = () => {
    return (
        <section className="flex flex-col py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-0">
            <div className="w-full h-full flex flex-col gap-14 items-center m-auto justify-center xl:max-w-6.5xl">
                {/*Section Title and Subtitle */}
                <div className="flex flex-col gap-3 max-w-[43.75rem] w-full items-center  text-coolBlack">
                    <h2 className="text-3xl  font-bold sm:text-[2.75rem] text-center">Benefits of Dove Payment</h2>
                    <p className="text-base font-normal text-center sm:text-xl">Thousands of forward-thinking healthcare businesses rely on Dove everyday to turbo-charge their business financial payments</p>
                </div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-12">
                    {benefits.map((item, id) => (
                        <BenefitCard
                            src={item.icon}
                            alt={item.title}
                            title={item.title}
                            txt={item.txt}
                            key={id}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Benefits