import { Button } from "../Button";

const Hero = () => {
    return (
        <section className="bg-heroImg bg-black bg-no-repeat bg-cover bg-center flex items-center min-h-screen h-full w-full px-4 sm:px-14 xl:px-0">
            <div className="w-full h-full flex flex-col gap-12 items-center justify-center m-auto xl:max-w-6.5xl">
                <div className="flex flex-col ">
                    <h1 className=" text-paleRobinEggBlue text-[30px] sm:text-[40px] md:text-[56px] lg:text-7xl text-center font-semibold mb-[10px] font-poppins">Payment Gateway</h1>
                    <h1 className=" text-paleRobinEggBlue text-[30px] sm:text-[40px] md:text-[56px] lg:xl:text-7xl text-center font-semibold  font-poppins mb-6">Solution that works</h1>
                    <p className="font-inter container m-auto px-4 text-ghostWhite text-[16px] sm:text-[22px] md:text-[26px] text-center md:px-8 lg:px-32">Dove provides a secure online and offline payment portal for healthcare facilities and entities to cater for expenses</p>
                </div>
                <Button
                    to="/register"
                    linkbtn
                    sx={{
                    padding: "0.75rem 2.875rem",
                    }}
                >
                    Create an account in 10 minutes
                </Button>
            </div>
        </section>
    );
}

export default Hero