import { confirmation } from "../../assets"; 

// hardcoded objects to be later replaced dynamically by backend/database.
const invoiceDetails = [
    {
        id: 1,
        title: "Order Date", 
        text: "12/04/21", 
    },
    {
        id: 2,
        title: "Order No.", 
        text: "5543-0116", 
    },
    {
        id: 3,
        title: "Payment Method", 
        text: "Bank", 
    },
]; 

const equipmentMaintenance = [
    {
        id: 1,
        title: "Vendor", 
        text: "Toptal Gimz", 
    },
    {
        id: 2,
        title: "Subtotal", 
        text: "GHS 219.78", 
    },
    {
        id: 3,
        title: "Service Charge", 
        text: "GHS 15.55", 
    },
];

const invoiceTotal = [
    {
        id: 1,
        title: "Total", 
        text: "GHS 475.22", 
    }, 
]; 

const Confirmation = () => {
    return (
        <section className="w-full min-h-[calc(100vh-65px)]">
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="flex items-center justify-center mb-5">
                        <h1 className="font-poppins font-semibold text-coolBlack text-[40px] lg:text-[50px] xl:text-[64px] text-center">Payment Confirmation</h1>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <div className="flex flex-col flex-1 lg:mr-5 mb-10 lg:mb-0">
                            <div className="bg-white p-10 rounded-[40px] drop-shadow-xl">
                                <div className="flex flex-col items-center justify-center my-10">
                                    <h1 className="font-poppins font-semibold md:text-[40px] text-[32px] text-coolBlack leading-[60px] text-center mb-10">Payment confirmed.</h1>
                                    <img className="max-w-[240px] h-full object-contain mb-10" src={confirmation} alt="payment confirmation"/>
                                    <p className="font-poppins text-[18px] md:text-[24px] text-coolBlack leading-[32px] text-center">Payment is confirmed and an invoice has been sent out to your email.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1 lg:ml-5 mb-10">
                            <div className="bg-white p-7 rounded-[40px] drop-shadow-xl">
                                <div className="flex flex-col items-center justify-center my-10">
                                    <h1 className="font-poppins font-semibold text-[30px] md:text-[36px] text-coolBlack leading-[72px] text-center">Invoice</h1>
                                    <p className="font-poppins text-[18px] md:text-[24px] text-policeBlue leading-[32px] text-center">Details</p>
                                </div>
                                <div className="flex flex-row">
                                    {invoiceDetails.map((detail, index) => (
                                        <div key={detail.id} className="flex flex-col flex-1">
                                            <h2 className={`font-poppins text-[18px] md:text-[20px] text-coolBlack/[0.6] mb-2 ${index === invoiceDetails.length-2 ? "text-center" : ""} ${index === invoiceDetails.length-1 ? "text-right" : ""}`}>{detail.title}</h2>
                                            <p className={`font-poppins text-[16px] md:text-[18px] text-policeBlue mb-2 ${index === invoiceDetails.length-2 ? "text-center" : ""} ${index === invoiceDetails.length-1 ? "text-right" : ""}`}>{detail.text}</p>
                                        </div> 
                                    ))}
                                </div>
                                {/* Empty Div */}
                                <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[2px] border-t-paleRobinEggBlue/[0.8]"></div>
                                <div className="flex flex-col">
                                    <div className="mb-2">
                                        <h1 className="font-poppins font-semibold text-[26px] md:text-[32px] text-coolBlack leading-[32px] mb-5">Equipment Maintenance</h1>
                                    </div>
                                    {equipmentMaintenance.map((detail) => (
                                        <div key={detail.id} className="flex flex-row justify-between items-center mb-5">
                                            <h2 className="font-poppins text-[18px] md:text-[20px] text-coolBlack md:leading-[32px]">{detail.title}</h2>
                                            <h3 className="font-poppins font-semibold text-[18px] md:text-[20px] text-coolBlack leading-[32px]">{detail.text}</h3>
                                        </div>
                                    ))}
                                </div>
                                {/* Empty Div */}
                                <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[2px] border-t-paleRobinEggBlue/[0.8]"></div>
                                <div className="flex flex-col">
                                    {invoiceTotal.map((detail) => (
                                        <div key={detail.id} className="flex flex-row justify-between items-center mb-5">
                                            <h2 className="font-poppins font-bold text-[32px] md:text-[36px] text-coolBlack leading-[46px]">{detail.title}</h2>
                                            <h3 className="font-poppins font-semibold text-[26px] md:text-[32px] text-coolBlack leading-[32px]">{detail.text}</h3>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex items-center justify-center">
                                    <button className="px-[65px] md:px-[130px] xl:px-[145px] py-[12px] xl:py-[14px] font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue rounded-[10px]  mb-7 drop-shadow-xl">Download Invoice</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Confirmation