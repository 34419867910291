import { Sidebar, FAQItem, ContactItem } from "../../components"; 

const Help = ({ user, setUser }) => {
    return (
        <>
        {user ? (
            <section className="flex w-full h-full">
                <Sidebar user={user} setUser={setUser} />
                <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                    <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                        <div className="bg-coolBlack rounded-xl p-5 md:p-10 w-full">
                            <div className="flex flex-col gap-2 flex-1 mb-5">
                                <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl mb-5">
                                    Some of the things you need to know about Dove
                                </h2>
                                <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF] mb-5">
                                    We answered questions so you dont need to ask.
                                </p>
                            </div>
                            <FAQItem />
                        </div>

                        <div className="bg-coolBlack rounded-xl p-5 md:p-10 w-full">
                            <div className="flex flex-col gap-2 flex-1 mb-5">
                                <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl mb-5">
                                    Contact Information
                                </h2>
                                <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF] mb-5">
                                    Feel free to reach out to us at anytime
                                </p>
                            </div>
                            <ContactItem />
                        </div>
                    </div>
                </div>
            </section>  
        ) : (
            <section className="flex w-full h-full">
                <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:px-0">
                    <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                        <div className="bg-coolBlack rounded-xl p-5 md:p-10 w-full">
                            <div className="flex flex-col gap-2 flex-1 mb-5">
                                <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl mb-5">
                                    Some of the things you need to know about Dove
                                </h2>
                                <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF] mb-5">
                                    We answered questions so you dont need to ask.
                                </p>
                            </div>
                            <FAQItem />
                        </div>

                        <div className="bg-coolBlack rounded-xl p-5 md:p-10 w-full">
                            <div className="flex flex-col gap-2 flex-1 mb-5">
                                <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl mb-5">
                                    Contact Information
                                </h2>
                                <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF] mb-5">
                                    Feel free to reach out to us at anytime
                                </p>
                            </div>
                            <ContactItem />
                        </div>
                    </div>
                </div>
            </section>  
        )}
        </>
    )
}

export default Help