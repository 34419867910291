import { Benefit1, Benefit2, Benefit3, Benefit4 } from "../assets/svgs";

export const benefits = [
    {
        title: "Verify and Track all Payments",
        txt: `Dove provides visibility and keeps a record of all
            business-related expenses processed via our platform. 
        Business owners can assign privileges and authority matrix to members of staff who are allowed to process expenses payments..`,
        icon: Benefit1,
    },
    {
        title: "Build your Credit Profile",
        txt: `Dove helps health facilities 
        that make on-time and regular business expense payments to build their
        credit scores by using their payment history to establish their credit profiles..`,
        icon: Benefit2,
    },
    {
        title: "Access to Loans and soft Capital",
        txt: `Dove in collaboration with our partners, 
        will support businesses having at least
        a B- on their credit score to access soft and flexible loans to support their business growth..`,
        icon: Benefit3,
    },
    {
        title: "Dove Merchants (Optional)",
        txt: `Dove merchants are physical Dove-branded mobile money vendors that provide an offline
        and USSD medium that ensures money is available for
        patients and hospital staff to cash-out and receive physical cash 24/7 on facility premises.`,
        icon: Benefit4,
    },
];