import { useState } from "react";
import { unlocked } from "../../assets"; 
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const PasswordReset = () => {
    // useState
    const [passwordView, setPasswordView] = useState(false); 
    const [confirmPasswordView, setConfirmPasswordView] = useState(false); 

    // formData 
    const [formData, setFormData] = useState({
        password: "", 
        confirmPassword: "", 
    });

    // destructure formData
    const { password, confirmPassword } = formData; 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        }));
    };

    // handleSubmit 
    const handleSubmit = (e) => {
        e.preventDefault(); 
        if(password !== confirmPassword) {
            alert("Password do not match"); 
        } else {
            console.log("Passwords match"); 
        };
    };

    // handlePasswordView
    const handlePasswordView = () => {
        setPasswordView((prev) => !prev); 
    }; 

    // handleConfirmPasswordView
    const handleConfirmPasswordView = () => {
        setConfirmPasswordView((prev) => !prev); 
    };

    return (
        <section className="h-[calc(100vh-65px)] w-full">
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="mb-10">
                    <img src={unlocked} alt="unlocked logo" className="w-[143px] h-[201px]"/>
                </div>
                <div>
                    <div className="mb-5">
                        <h1 className="font-cormorant text-coolBlack text-[28px] md:text-[64px] leading-[45px] text-center mb-10">Reset your password.</h1>
                        <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px] w-[90%] mx-auto text-center">Password must be different from previous password.</p>
                        </div>
                        <form className="flex flex-col w-[50%] mx-auto" onSubmit={handleSubmit}>
                            <div className="flex items-center border-b-[1.5px] border-coolBlack mb-5">
                                <input 
                                    className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                    type={!passwordView ? "password" : "text"}
                                    name="password"
                                    value={password}
                                    placeholder="New password*"
                                    onChange={handleChange}
                                    autoComplete="on"
                                />
                                {!passwordView ? <VisibilityOffOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/> : <RemoveRedEyeOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/>}
                            </div>
                            <div className="flex items-center border-b-[1.5px] border-coolBlack mb-5">
                                <input 
                                    className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                    type={!confirmPasswordView ? "password" : "text"}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    placeholder="Confirm new password*"
                                    onChange={handleChange}
                                    autoComplete="on"
                                />
                                {!confirmPasswordView ? <VisibilityOffOutlinedIcon onClick={handleConfirmPasswordView} id="icon" className="cursor-pointer"/> : <RemoveRedEyeOutlinedIcon onClick={handleConfirmPasswordView} id="icon" className="cursor-pointer"/>}
                            </div>
                            <div className="flex flex-row items-center justify-center w-[75%] m-auto">
                                <button className="font-poppins text-[13px] bg-paleRobinEggBlue rounded w-full py-3 font-bold">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
        </section>
    )
}

export default PasswordReset