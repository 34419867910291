import { envelope } from "../../assets"; 
import { Link } from "react-router-dom"; 

const CheckEmail = () => {
    return (
        <section className="h-[calc(100vh-65px)] w-full">
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="mb-10">
                    <img src={envelope} alt="envelope logo" className="w-[252px] h-[238px]"/>
                </div>
                <div>
                    <div className="mb-5">
                        <h1 className="font-cormorant text-coolBlack text-[28px] md:text-[64px] leading-[45px] text-center mb-10">Check your email.</h1>
                        <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px] w-[60%] mx-auto text-center">We have sent a password recover instructions to your email.</p>
                        </div>
                        
                        <div className="flex flex-col items-center justify-center w-[75%] m-auto">
                            <button className="font-poppins text-[13px] bg-paleRobinEggBlue rounded w-full py-3 mb-5 font-bold"><Link to="/">Back to Home</Link></button>
                            <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px] text-center">Did not receive an email? {" "}
                                <span className="underline underline-coolBlack"><Link to="/forgot-password">Resend email</Link></span>
                                <span> or check your Spam folder.</span>
                            </p>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default CheckEmail