import { useState } from "react";
import { locked } from "../../assets"; 

const ForgotPassword = () => {
    // formData 
    const [formData, setFormData] = useState({
        email: "", 
    });

    // destructure formData
    const { email } = formData; 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        }));
    };

    // handleSubmit 
    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log("Submitting..."); 
    };

    return (
        <section className="h-[calc(100vh-65px)] w-full">
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="mb-10">
                    <img src={locked} alt="locked logo" className="w-[143px] h-[201px]"/>
                </div>
                <div>
                    <div className="mb-5">
                        <h1 className="font-cormorant text-coolBlack text-[28px] md:text-[64px] leading-[45px] text-center mb-10">Forgot Password?</h1>
                        <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px] w-[60%] mx-auto text-center">Enter the Email associated with your account and we will send instructions on account password reset</p>
                    </div>
                    <form className="flex flex-col w-[50%] mx-auto" onSubmit={handleSubmit}>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-10">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type="email"
                                name="email"
                                value={email}
                                placeholder="Enter Email*"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center w-[75%] m-auto">
                            <button className="font-poppins text-[13px] bg-paleRobinEggBlue rounded w-full py-3 font-bold">Reset Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword