import { useState } from "react"; 
import { Link } from "react-router-dom"; 
import Sidebar from "../Sidebar/Sidebar";

const Checkout = ({ user, setUser }) => {
    // formData
    const [formData, setFormData] = useState({
        amount: "", 
        vendor: "GHS",
        bank: "GHS Bank",
        accountNumber: "",
    });

    // destructure formData
    const { amount, vendor, bank, accountNumber } = formData; 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        })); 
    };

    // handleSubmit
    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log(amount, vendor, bank, accountNumber); 
    };

    return (
        <section className="flex w-full h-full">
            <Sidebar user={user} setUser={setUser} />
            
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="bg-cultured h-full w-full">
                        <div className="lg:flex">
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <h1 className="font-poppins font-semibold text-[32px] lg:text-[40px] xl:text-[48px] text-coolBlack text-center xx:mb-5 lg:mb-0 ">Service Payment Checkout</h1>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:justify-end">
                                <div className="bg-coolBlack rounded-[28px] flex-col p-6 drop-shadow-xl">
                                    <h1 className="font-poppins font-semibold leading-[42px] text-[22px] xl:text-[28px] text-white text-center">Current Balance <span>${235.43}</span></h1>
                                    <p className="font-poppins text-white text-center">Item Charges: <span>{`GHS 1`}</span></p>
                                    <p className="font-poppins text-white text-center">Service Charges: <span>{`GHS 0.1`}</span></p>
                                    <p className="font-poppins text-white text-center">Balance After Purchase: <span>{`GHS 234.42`}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <form className="flex flex-col w-full h-full rounded-[40px] bg-white mt-10 drop-shadow-xl" onSubmit={handleSubmit}>
                                <div className="flex flex-col my-10">
                                    <h1 className="font-poppins font-semibold text-coolBlack text-[32px] xl:text-[64px] leading-[50px] xl:leading-[72px] text-center mb-5 px-4">Equipment Maintenance.</h1>
                                    <p className="font-poppins text-policeBlue text-[20px] xl:text-[24px] leading-[32px] text-center px-4">Fill in recipient's details and payment options.</p>
                                </div>
                                <div className="flex flex-col px-10">
                                    <label htmlFor="amount" className="font-poppins  text-coolBlack text-[18px] xl:-text[22px] mb-2">Amount</label>
                                    <input 
                                        className="font-poppins  text-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite block mb-5 px-5 py-2"
                                        id="amount"
                                        type="text"
                                        name="amount"
                                        value={amount}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-col px-10">
                                    <label htmlFor="vendor" className="font-poppins  text-coolBlack text-[18px] xl:-text[22px] mb-2">Select vendor</label>
                                        <select 
                                            className="block w-full font-poppins text-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-5 px-3 py-2 " 
                                            value={vendor}
                                            onChange={handleChange}
                                            name="vendor"
                                            id="vendor"
                                        >
                                            <option defaultValue>GHS</option>
                                        </select>
                                </div>
                                <div className="flex flex-col px-10">
                                    <label htmlFor="bank" className="font-poppins  text-coolBlack text-[18px] xl:-text[22px] mb-2">Select bank</label>
                                        <select 
                                            className="block w-full font-poppins text-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite mb-5 px-3 py-2 " 
                                            value={bank}
                                            onChange={handleChange}
                                            name="bank"
                                            id="bank"
                                        >
                                            <option defaultValue>GHS Bank</option>
                                        </select>
                                </div>
                                <div className="flex flex-col px-10 mb-5">
                                    <label htmlFor="accountNumber" className="font-poppins text-coolBlack text-[18px] xl:-text[22px] mb-2">Account Number</label>
                                    <input 
                                        className="font-poppins text-coolBlack text-[16px] rounded-[40px] bg-antiFlashWhite block mb-5 px-5 py-2"
                                        id="accountNumber"
                                        type="text"
                                        name="accountNumber"
                                        value={accountNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-col items-center justify-center mb-5">
                                    <button className="px-[65px] md:px-[130px] xl:px-[164px] py-[12px] xl:py-[14px] font-poppins font-semibold text-coolBlack bg-paleRobinEggBlue rounded-[10px] drop-shadow-xl mb-7">Submit</button>
                                    <p className="font-poppins text-[14px] px-10 text-center">By clicking Submit you agree to our <span className="underline text-tealBlue font-bold leading-[36px]"><Link to="/general-terms-and-conditions">Terms and Conditions</Link></span> and <span className="text-tealBlue font-bold leading-[36px]"><Link to="/privacy-policy">Privay Policy</Link></span>.</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Checkout