const Subscribe = () => {
    return (
        <section className="flex flex-col gap-10 items-center justify-center min-h-[50vh] text-coolBlack px-4 py-6 sm:py-10  sm:px-14 w-full xl:px-0 m-auto xl:max-w-6.5xl">
            <div className="flex flex-col gap-5 items-center text-poppins">
                <h4 className=" text-2xl font-bold md-text-[2.5rem]">
                Sign up to our newsletter
                </h4>
                <p className="text-lg md:text-xl text-center max-w-[900px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim non ac
                proin vitae massa. Mi mollis sed nunc hac sed sodales tristique erat
                sed non adipiscing ullamcorper. Ultricies orci hendrerit dui lacus vel
                quam massa..{" "}
                </p>
            </div>
            <form action="" className="flex w-full justify-center">
                <input
                type="text"
                placeholder="name@email.com"
                className="bg-coolBlack p-[0.635rem] text-xs rounded-md text-white placeholder:text-white placeholder:text-xs rounded-r-none h-14 max-w-[300px] w-full"
                />
                <button className="bg-paleRobinEggBlue rounded-md rounded-l-none p-[0.635rem] px-4 md:px-8 text-coolBlack font-bold border-0 outline-0">
                Subscribe
                </button>
            </form>
        </section>
    );
}

export default Subscribe