export const notifications = [
    {
        id: 1, 
        title: "Receive newsletters to email", 
        status: "off", 
    },
    {
        id: 2, 
        title: "Receive newsletters to email", 
        status: "off", 
    },
    {
        id: 3, 
        title: "Email invoice after transaction", 
        status: "on", 
    },
    {
        id: 4, 
        title: "Email invoice after transaction", 
        status: "on", 
    },
]; 