import { ContactItem } from "../../components"; 

const Contact = () => {
    return (
        <section className="flex items-center w-full bg-coolBlack min-h-screen h-full px-4 py-6 sm:py-10 sm:px-14 xl:px-0">
            <div className="flex flex-col gap-10 lg:flex-row m-auto xl:max-w-6.5xl md:gap-14 lg:gap-[7.5rem] w-full">
                <div className="flex flex-col gap-2 flex-1">
                    <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl">
                        Contact Information
                    </h2>
                    <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF]">
                        Feel free to reach out to us at anytime
                    </p>
                </div>
                <ContactItem />
            </div>
        </section>
    )
}

export default Contact