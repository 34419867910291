import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { paymentStatistics, Dots } from "../../assets";
import { Sidebar, MemberCard, PersonalCard } from "../../components"; 

const Deposit = ({ user, setUser }) => {
    // useNavigate
    const navigate = useNavigate(); 

    // useEffect
    useEffect(() => {
        if(!user) {
            navigate("/"); 
        }
    }, [user, navigate]);
    
    return (
        <section className="flex w-full h-full">
            <Sidebar user={user} setUser={setUser} />
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="bg-white rounded-xl p-5 md:p-10 w-full">
                        <div className="flex items-start lg:mb-4 mb-2 flex-col lg:flex-row">
                            <div className="mb-4 lg:mb-0">
                                <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Deposit</h3>
                                <p className="font-poppins text-coolBlack/[0.6]">Overview</p>
                            </div>
                        </div>
                        
                        <div className="md:flex flex-col">
                            <div className="flex flex-col xl:flex-row gap-5 mb-5">
                                <div className="flex flex-1 items-center xl:justify-start">
                                    <MemberCard />
                                </div>
                                <div className="flex flex-1 items-center xl:justify-end">
                                    <PersonalCard />
                                </div>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <div className="flex flex-1.5 flex-col my-5">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Payment Statistics</h3>
                                        <Dots />
                                    </div>
                                    <img src={paymentStatistics} alt="stats" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Deposit