import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar"; 
import { adminTable, adminData } from "../../constants/admin"; 
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const Admin = ({ user, setUser }) => {
    // useState
    const [passwordView, setPasswordView] = useState(false); 

    // useNavigate
    const navigate = useNavigate(); 

    // useEffect
    useEffect(() => {
        if(!user) {
            navigate("/"); 
        }
    }, [user, navigate]);

    // handlePasswordView
    const handlePasswordView = () => {
        setPasswordView((prev) => !prev); 
    }; 

    return (
        <section className="flex w-full min-h-screen">
            <Sidebar user={user} setUser={setUser} />
            <div className="bg-cultured p-10 h-full w-full xl:px-32">
                <div className="flex items-center justify-between mb-6 flex-col lg:flex-row">
                    <div className="mb-4 lg:mb-0">
                        <h1 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Admin</h1>
                    </div>
                    <div className="flex gap-4">
                        <button className="bg-paleRobinEggBlue text-coolBlack px-4 py-2 rounded-md font-semibold tracking-wide cursor-pointer font-poppins mb-5 lg:mb-0 text-[14px]">Add Admin Profile</button>
                        <button className="bg-red-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer font-poppins mb-5 lg:mb-0 text-[14px]">Delete Admin</button>
                    </div>
                </div>
                <div>
                    <div className="overflow-auto rounded-lg shadow hidden lg:block -mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
                        <table className="w-full">
                            <thead className="bg-coolBlack">
                                {adminTable.map((admin) => (
                                    <tr key={admin.id} className="mb-2 lg:mb-0">
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{admin.avatar}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{admin.name}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{admin.email}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{admin.password}</th>
                                        <th className=" font-poppins p-5 text-left text-xs font-semibold uppercase tracking-wider text-white">{admin.status}</th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {adminData.map((admin, index) => (
                                    <tr key={admin.id} className={`${index % 2 === 0 ? "bg-coolBlack/[0.6]" : "bg-paleRobinEggBlue/[0.6]"}`}>
                                        <td className="p-3 whitespace-nowrap">
                                            <div className="w-10 h-10">
                                                <img 
                                                    className="w-full h-full rounded-full"
                                                    src={admin.profile}
                                                    alt="admin profile pic" 
                                                />
                                            </div>
                                        </td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">{admin.name}</td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">{admin.email}</td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">
                                            {!passwordView ? 
                                                <span>
                                                    ************
                                                    <VisibilityOffOutlinedIcon onClick={handlePasswordView} id="icon" className="float-right cursor-pointer" />
                                                </span>
                                                : 
                                                <span>
                                                    {admin.password}
                                                    <RemoveRedEyeOutlinedIcon onClick={handlePasswordView} id="icon" className="float-right cursor-pointer"/>
                                                </span>
                                            }
                                        </td>
                                        <td className="font-poppins p-3 text-sm text-coolBlack whitespace-nowrap">
                                            <span className={`p-1.5 text-xs font-medium uppercase tracking-wider text-coolBlack  rounded-lg bg-opacity-80 ${index % 2 === 0 ? "bg-coolBlack text-white" : "bg-red-500 text-white"}`}>{admin.status}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:hidden">
                        {adminData.map((admin) => (                            
                            <div key={admin.id} className="bg-white space-y-3 p-4 rounded-lg shadow-lg">
                                <div className="flex items-center justify-between space-x-2 text-sm w-full">
                                    <div className="w-10 h-10">
                                        <img 
                                            className="w-full h-full rounded-full"
                                            src={admin.profile}
                                            alt="admin profile pic" 
                                        />
                                    </div>
                                    <div className="text-gray-500">{admin.name}</div>
                                        <div>
                                            <span
                                            className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{admin.status}</span>
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium text-gray-700">
                                        {admin.email}
                                    </div>
                                    <div className="text-sm font-normal text-black">
                                        {!passwordView ? 
                                            <span>
                                                ************
                                                <VisibilityOffOutlinedIcon onClick={handlePasswordView} id="icon" className="float-right cursor-pointer" />
                                            </span>
                                            : 
                                            <span>
                                                {admin.password}
                                                <RemoveRedEyeOutlinedIcon onClick={handlePasswordView} id="icon" className="float-right cursor-pointer"/>
                                            </span>
                                        }
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Admin