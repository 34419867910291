const PersonalCard = () => {
    return (
        <div className="flex flex-col bg-coolBlack rounded-[28px] p-6 drop-shadow-xl min-h-[200px] w-[350px]">
            <div className="flex flex-row flex-1">
                <h1 className="font-poppins font-normal text-white text-[14px]">Debit Card</h1>
            </div>
            <div className="flex flex-row justify-between items-center flex-1">
                <h3 className="font-poppins font-bold leading-[36px] text-white text-[24px]">2623</h3>
                <h3 className="font-poppins font-bold leading-[36px] text-white text-[24px]">4632</h3>
                <h3 className="font-poppins font-bold leading-[36px] text-white text-[24px]">5748</h3>
                <h3 className="font-poppins font-bold leading-[36px] text-white text-[24px]">2774</h3>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center">
                    <p className="font-poppins text-normal text-white text-[14px] opacity-[50%]">0023</p>
                </div>
                <div className="flex flex-col items-end">
                    <p className="font-poppins text-normal text-white text-[14px] opacity-[50%]">Valid Thru</p>
                    <p className="font-poppins text-normal text-white text-[14px] opacity-[50%]">09/25</p>
                </div>
                <div className="flex flex-col items-start">
                    <p className="font-poppins text-normal text-white text-[14px] opacity-[50%]">Olukoya Michaels</p>
                </div>
            </div>
        </div>
    )
}

export default PersonalCard