import { useState } from "react";
import { ArrowDown, ArrowUpBlu } from "../../assets/svgs"; 
import { faqs } from "../../constants/faqs"; 

const FAQ = () => {
    const [selected, setSelected] = useState(null); 

    const toggle = (index) => {
        if(selected === index) {
            return setSelected(null); 
        }; 
        setSelected(index); 
    }; 

    return (
        <section className="flex items-center w-full bg-coolBlack min-h-screen h-full px-4 py-6 sm:py-10 sm:px-14 xl:px-0">
            <div className="flex flex-col gap-10 lg:flex-row m-auto xl:max-w-6.5xl md:gap-14 lg:gap-[7.5rem] w-full">
                {/* Section title/ left column */}
                <div className="flex flex-col gap-2 flex-1">
                    <h2 className="text-white text-2xl font-poppins font-bold md:text-4xl">
                        Some of the things you need to know about Dove
                    </h2>
                    <p className="font-poppins text-lg md:text-xl font-normal text-[#8FE3CF]">
                        We answered questions so you dont need to ask.
                    </p>
                </div>
                {/* Faqs */}
                <div className="flex flex-col gap-5 flex-1">
                    {faqs.map((item, index) => {
                        return (
                            <div key={index} className={selected === index
                                ? "flex flex-col py-[1.0635rem] px-8 bg-[#FAFAFF] transition-all  border-b-[0.3px] border-solid border-[#98A1B0] "
                                : "flex flex-col gap-3 border-b-[0.3px] border-solid border-[#98A1B0] transition-all pb-2 md:pb-3"}
                            >
                                <div
                                    className="flex items-center justify-between cursor-pointer text-poppins"
                                    onClick={() => toggle(index)}
                                >
                                    <h4 className={selected === index
                                            ? "text-lg text-coolBlack  font-bold"
                                            : "text-white text-lg "}
                                    >
                                        {item.title}
                                    </h4>
                                    <div>
                                        {selected === index ? <ArrowUpBlu /> : <ArrowDown />}
                                    </div>
                                </div>
                                <p className={selected === index
                                    ? "font-normal text-lg text-coolBlack max-h-[999px] transition-[height]"
                                    : "max-h-[0] overflow-hidden transition-[height]"}
                                >
                                    {item.text}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    )
}; 

export default FAQ