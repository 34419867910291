import { services1, services2, services3, services4, services5, services6 } from "../assets/images";

export const services = [
    {
        title: "Equipment Maintenance",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services1,
    },
    {
        title: "Laundering Service",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services2,
    },
    {
        title: "Staff Training and Events",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services3,
    },
    {
        title: "Waste Disposal",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services4,
    },
    {
        title: "Drug Supplier",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services5,
    },
    {
        title: "Transportation",
        subtxt: "Processing Time:",
        tm: " 3 - 5 minutes",
        src: services6,
    },
];