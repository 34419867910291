import { Link } from "react-router-dom";
import {
    FooterLogo,
    Instagram,
    SnapChat,
    Twitter,
    Whatsapp,
} from "../../assets";
import { footerData } from "../../constants/footer";

const Footer = () => {
    return (
        <footer className="bg-coolBlack w-full px-4 py-6 sm:py-10  sm:px-14  xl:px-0">
            <div className="flex flex-col gap-10 m-auto xl:max-w-6.5xl text-poppins">
                {/* Main Content */}
                <div className="flex flex-col lg:flex-row lg:justify-between gap-12 lg:gap-[120px]">
                    <div className="flex flex-col gap-8 w-full lg:w-[55%]">
                        <div>
                            <img src={FooterLogo} alt="dove logo" />
                        </div>
                        <div className="flex flex-col md:flex-row md:flex-wrap gap-5 md:gap-20 md:justify-between">
                            {footerData.map((item, id) => (
                                <div className="flex flex-col gap-3 md:gap-6 text-white " key={id}>
                                    <p className="mb-1 text-paleRobinEggBlue text-lg md:text-2xl font-bold ">
                                        {item.title}
                                    </p>
                                    <div className="flex flex-col gap-2 md:gap-5">
                                        {item.links.map((link, id) => (
                                            <Link to={link === "privacy policy" ? "privacy-policy" : link === "terms and conditions" ? "terms-and-conditions" : link} key={id}>
                                                <span className="text-sm md:text-base font-normal capitalize">
                                                {link}
                                                </span>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Contact */}
                    <div className="flex flex-col lg:gap-[50px]">
                        {/* socials */}
                        <div className="flex gap-8 lg:justify-items-end items-center">
                            <Whatsapp />
                            <Instagram />
                            <SnapChat />
                            <Twitter />
                        </div>
                        {/* Address */}
                        <div className="flex flex-col gap-5 ">
                            <div className="flex flex-col text-paleRobinEggBlue text-base">
                                <p className="font-bold">Ghana</p>
                                <span className="font-normal">
                                    B/708, Ashaley Botwe, Sraha Down,
                                    <br /> GD-141-0858 <br />
                                    233 246 614 835
                                </span>
                            </div>
                            <div className="flex flex-col text-paleRobinEggBlue text-base">
                                <p className="font-bold">USA </p>
                                <span className="font-normal">
                                    643 Mission Street, San Francisco, CA 94105
                                    <br /> 8 The Green, Ste A, Dover DE 19901 <br />
                                    (228) 338 6693
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Copyrights */}
                <p className="text-paleRobinEggBlue text-base font-normal">2022 Dove Payments</p>
            </div>
        </footer>
    );
}

export default Footer