import { Hero, Partners, Services, Benefits, FAQ, Subscribe } from "../../components"; 

const Home = () => {
    return (
        <>
            <Hero />
            <Partners />
            <Services /> 
            <Benefits />
            <FAQ />
            <Subscribe />
        </>
    )
}

export default Home