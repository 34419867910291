import { Link } from "react-router-dom";
import { Button } from "../Button"; 

const ServiceCard = ({src, alt, title, subtxt, time}) => {
    return (
        <div className="flex flex-col bg-white p-4 gap-6 rounded-md">
            <img
                src={src}
                alt={alt}
                className="rounded-[1rem] w-full h-[11.25rem] object-cover"
            />
            {/* text container */}
            <div className="flex flex-col gap-2 font-poppins text-coolBlack">
                <h4 className="font-semibold text-xl lg:text-2xl">{title}</h4>
                <p className="font-bold text-base lg:text-lg">{subtxt}</p>
                <span className='font-normal text-base lg:text-lg'>{time}</span>
            </div>
            <Link to="/checkout" className="flex items-center justify-center">
                <Button darkbtn>
                    Make Payment 
                </Button>
            </Link>
        </div>
    );
}

export default ServiceCard