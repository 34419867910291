const BenefitCard = ({src, alt, title, txt}) => {
    return (
        <div className="flex flex-col items-center bg-white p-4 gap-2 rounded-md sm:items-start">
            <img
                src={src}
                alt={alt}
                className="w-[60px] h-[60px]"
            />
            {/* text container */}
            <div className="flex flex-col gap-2 font-poppins ">
                <h4 className="font-semibold text-center  text-coolBlacktext-xl lg:text-2xl text-coolBlack sm:text-left">{title}</h4>
                <p className="font-normal text-sm text-center  text-[#256D85] sm:text-left">{txt}</p>
            </div>
        </div>
    );
}

export default BenefitCard