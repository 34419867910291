export const faqs = [
    {
        id: 1, 
        title: "Who can sign up on Dove?", 
        text: "An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?", 
    },
    {
        id: 2, 
        title: "Who can sign up on Dove?", 
        text: "An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?", 
    },
    {
        id: 3, 
        title: "Who can sign up on Dove?", 
        text: "An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?", 
    },
    {
        id: 4, 
        title: "Who can sign up on Dove?", 
        text: "An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?", 
    },
    {
        id: 5, 
        title: "Who can sign up on Dove?", 
        text: "An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?", 
    },
];