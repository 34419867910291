export const contactAddress = [
    {
        id: 1, 
        country: "Ghana", 
        address: "B/708, Ashaley Botwe, Sraha Down",
        zip: "GD-141-0858", 
        phone: "233 246 614 835", 
    },
    {
        id: 2, 
        country: "USA", 
        address: "643 Mission Street, San Francisco",
        zip: "CA 94105", 
        phone: "(228) 338 6693", 
    },
];