import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar, MemberCard, PersonalCard } from "../../components"; 
import { paymentStatistics, transactions, Dots } from "../../assets";

const Dashboard = ({ user, setUser }) => {
    // useNavigate
    const navigate = useNavigate(); 

    // useEffect
    useEffect(() => {
        if(!user) {
            navigate("/login"); 
        };
    }, [user, navigate]); 

    return (
        <section className="flex w-full min-h-screen">
            <Sidebar user={user} setUser={setUser} />
            <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                <div className="w-full h-full flex flex-col gap-14 items-center mx-auto justify-center xl:max-w-6.5xl">
                    <div className="bg-white rounded-xl p-5 md:p-10 w-full">
                        <div className="flex items-start mb-6 flex-col lg:flex-row">
                            <div className="mb-4 lg:mb-0">
                                <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Dashboard</h3>
                                <p className="font-poppins text-coolBlack/[0.6]">Payment Overview</p>
                            </div>
                        </div>

                        {/* NEEDS WORK */}

                        {/* <div className="md:flex flex-col">
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <div className="flex flex-1.5 flex-col my-5">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Payment Statistics</h3>
                                        <Dots />
                                    </div>
                                    <img src={paymentStatistics} alt="stats" />
                                </div>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <div className="flex flex-1.5 flex-col my-5">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-poppins font-bold text-coolBlack text-[32px] leading-[48px]">Recent Transactions</h3>
                                        <Dots />
                                    </div>
                                    <img src={transactions} alt="stats" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-10">
                                <div className="flex flex-1 items-center justify-center lg:justify-end">
                                    <MemberCard />
                                </div>
                                <div className="flex flex-1 items-center justify-center lg:justify-end">
                                    <PersonalCard />
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Dashboard