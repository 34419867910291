import { ServiceCard, Sidebar } from "../../components"; 
import { services } from "../../constants/services";

const Services = ({ user, setUser }) => {
    return (
        <>
        {user ? (
            <>
                <section className="flex w-full h-full">
                    <Sidebar user={user} setUser={setUser} />
                    <div className="flex py-10 min-h-screen h-full w-full px-4 sm:px-14 xl:px-32 2xl:mr-48">
                        <div className="w-full h-full flex flex-col gap-14 items-center m-auto justify-center xl:max-w-6.5xl">
                            {/*Section Title and Subtitle */}
                            <div className="flex flex-col gap-3 max-w-[43.75rem] w-full items-center  text-coolBlack">
                                <h2 className="text-3xl  font-bold sm:text-[2.75rem] text-center">Our Services</h2>
                                <p className="text-base font-normal text-center sm:text-xl">Top-end financial tools to help your business with its financial operations and cash flow.</p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-12">
                                {services.map((item, id) => (
                                    <ServiceCard
                                        src={item.src}
                                        alt={item.title}
                                        title={item.title}
                                        subtxt={item.subtxt}
                                        time={item.tm}
                                        key={id}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        ) : (
            <section className="flex flex-col py-10  min-h-screen h-full w-full px-4   sm:px-14 xl:px-0">
                <div className="w-full h-full flex flex-col gap-14 items-center m-auto justify-center xl:max-w-6.5xl">
                    {/*Section Title and Subtitle */}
                    <div className="flex flex-col gap-3 max-w-[43.75rem] w-full items-center  text-coolBlack">
                        <h2 className="text-3xl  font-bold sm:text-[2.75rem] text-center">Our Services</h2>
                        <p className="text-base font-normal text-center sm:text-xl">Top-end financial tools to help your business with its financial operations and cash flow.</p>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-12">
                        {services.map((item, id) => (
                            <ServiceCard
                                src={item.src}
                                alt={item.title}
                                title={item.title}
                                subtxt={item.subtxt}
                                time={item.tm}
                                key={id}
                            />
                        ))}
                    </div>
                </div>
            </section>
        )}
        </>
    );
};

export default Services