import { Link } from "react-router-dom";

export const Button = ({linkbtn, children, to, sx, darkbtn}) => {
  return (
    <>
      {linkbtn && (
        <Link to={to}>
          <button
            style={sx}
            className="bg-paleRobinEggBlue rounded-xl text-lg font-semibold text-coolBlack px-8 py-2"
          >
            {children}
          </button>
        </Link>
      )}
      {darkbtn && (
        <button className="bg-coolBlack border-0 outline-0 text-white text-poppins flex items-center justify-center font-semibold py-[8px] px-[32px] rounded-lg text-lg">
          {children}
        </button>
      )}
    </>
  );
};
