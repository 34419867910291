export const footerData = [
    {
        title: "Information",
        links: ["FAQ", "help", "contact"],
    },
    {
        title: "Home",
        links: ["about", "services", "features"],
    },
    {
        title: "License",
        links: ["privacy policy", "terms and conditions"],
    },
];