import { contactAddress } from "../../constants/contact"; 

const ContactItem = () => {
    return (
        <div className="flex gap-5 justify-between md:flex-row flex-col">
            <div className="flex flex-col flex-1">
                <h1 className="font-poppins font-semibold text-[26px] text-white">Address</h1>
                {contactAddress.map((contact) => (
                    <div key={contact.id} className="mb-5">
                        <p className="font-poppins font-bold text-paleRobinEggBlue">{contact.country}</p>
                        <span className="font-poppins font-normal text-paleRobinEggBlue">
                            {contact.address}
                            <br /> {contact.zip} <br />
                            {contact.phone}
                        </span>
                    </div>
                ))}
            </div>
            <div className="flex flex-col flex-1">
                <div className="flex flex-col mb-5">
                    <h1 className="font-poppins font-semibold text-[26px] text-white">Customer Care Number</h1>
                    <p className="font-poppins font-bold text-paleRobinEggBlue">(704) 555-0127</p>
                </div>
                <div className="flex flex-col">
                    <h1 className="font-poppins font-semibold text-[26px] text-white">Email</h1>
                    <p className="font-poppins font-bold text-paleRobinEggBlue">willie.jennings@example.com</p>
                </div>
            </div>
        </div>
    )
}

export default ContactItem