import { useState, useEffect } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { doctor1 } from "../../assets"; 
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';

async function LoginUser(credentials) {
    // return fetch('http://localhost/api-dove-nkomor2/public/api/login',{
        return fetch('https://api.dove.nkomor.com/public/api/login',{
            method: 'POST',
            header: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(credentials)
        }).then(data => console.log(data))
}

const Login = ({ user, setUser }) => {
    // useState 
    const [passwordView, setPasswordView] = useState(false); 

    // formData
    const [formData, setFormData] = useState({
        email: "tobiakindele@gmail.com",
        password: "akindele", 
    });
    // destructure formData
    const { email, password } = formData; 

    // useNavigate
    const navigate = useNavigate(); 
    
    // useEffect
    useEffect(() => {
        if(user) {
            console.log("USER IS LOGGED IN MF"); 
            navigate("/"); 
        }; 
    }, [user, navigate]); 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        }));
    }; 

    // handleSubmit
    const handleSubmit = async (e) => {
        e.preventDefault(); 
        
        // console.log(email, "email")
        const user = await LoginUser({
            email,
            password
        })
        setUser(user)

        // setUser("Danny"); 
        // console.log("Hello Danny"); 
        navigate("/profile"); 
    };

    // handlePasswordView
    const handlePasswordView = () => {
        setPasswordView((prev) => !prev); 
    }; 

    return (
        <section className="flex flex-row h-[calc(100vh-65px)] w-full">
            <div className="hidden md:flex md:flex-1">
                <img src={doctor1} alt="login img" className="h-full w-full object-cover"/>
            </div>
            <div className="flex flex-1 flex-col">
                <div className="flex flex-col justify-center items-center max-w-[85%] m-auto">
                    <div className="mb-5">
                        <h1 className="font-cormorant text-coolBlack text-[28px] md:text-[48px] leading-[45px] text-center">Welcome Back,</h1>
                        <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px]">Don't have an account? {" "}
                            <span className="underline underline-coolBlack"><Link to="/register">Create an account</Link></span>
                        </p>
                    </div>
                    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-5">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type="email"
                                name="email"
                                value={email}
                                placeholder="Enter Email*"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-3">
                            <input 
                                className="block appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type={!passwordView ? "password" : "text"}
                                name="password"
                                value={password}
                                placeholder="Enter Password*"
                                onChange={handleChange}
                                autoComplete="on"
                            />
                            {!passwordView ? <VisibilityOffOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/> : <RemoveRedEyeOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/>}
                        </div>
                        <div className="mb-5">
                            <span><PanoramaFishEyeOutlinedIcon id="icon"/><Link to="/forgot-password" className="font-poppins text-[12px] md:text-[14px] text-tealBlue px-1">Forgot password?</Link></span>
                        </div>
                        <div className="flex flex-row items-center justify-center w-[75%] m-auto">
                            <button className="font-poppins text-[13px] bg-paleRobinEggBlue rounded w-full py-3 font-bold">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Login