import { navLinksMobileUser } from '../../constants/nav';
import { Link, useNavigate } from "react-router-dom"; 
import { Help, Logout } from '../../assets';

const Sidebar = ({ user, setUser }) => {
    // useNavigate
    const navigate = useNavigate(); 

    // handleUserLogOut
    const handleUserLogOut = () => {
        console.log("User Log Out"); 
        setUser(""); 
        navigate("/"); 
    };

    return (
        <section className="bg-white">
            <div className="md:flex flex-col justify-between hidden min-h-[calc(100vh-65px)] px-[20px] pt-[40px]">
                <ul className="flex flex-col gap-[2.1rem]">
                    {navLinksMobileUser.map((nav) => (
                        <li key={nav.id} className="flex items-center p-[5px] cursor-pointer gap-5 hover:bg-[#ece8ff]">
                            <Link to={nav.url} className="flex gap-5">
                            {nav.logo}
                                <span className="font-poppins text-[16px] font-bold text-coolBlack/[0.6]">
                                    {nav.title}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
                <ul className="flex flex-col gap-[2.1rem] mb-20">
                    <li className="flex items-center p-[5px] cursor-pointer gap-5 hover:bg-[#ece8ff]">
                        <Link to="/help" className="flex gap-5">
                            <Help />
                            <span className="font-poppins text-[16px] font-bold text-coolBlack/[0.6]">
                                Help
                            </span>
                        </Link>
                    </li>
                    <li className="flex items-center p-[5px] cursor-pointer gap-5 hover:bg-[#ece8ff]">
                        <Logout />
                        <span className="font-poppins text-[16px] font-bold text-coolBlack/[0.6]" onClick={handleUserLogOut}>
                            Log Out
                        </span>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Sidebar