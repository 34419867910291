import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages"; 
import { Admin, Navbar, CheckEmail, Checkout, Confirmation, Dashboard, FAQ, ForgotPassword, Help, Login, PasswordReset, Profile, Register, Services, Footer, Deposit, Settings, Transactions, Contact, PrivacyPolicy, TermsAndConditions } from "./components";

const App = () => {
    const [user, setUser] = useState("d");

    return (
        <>
            <Router>
                <Navbar user={user} setUser={setUser} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login user={user} setUser={setUser} />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/check-email" element={<CheckEmail />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                    <Route path="/register" element={<Register user={user} setUser={setUser} />} />
                    <Route path="/checkout" element={<Checkout user={user} />} />
                    <Route path="/confirmation" element={<Confirmation />} />
                    <Route path="/profile" element={<Profile user={user} setUser={setUser} />} />
                    <Route path="/admin" element={<Admin user={user} setUser={setUser} />} />
                    <Route path="/dashboard" element={<Dashboard user={user} setUser={setUser} />} />
                    <Route path="/services" element={<Services user={user} setUser={setUser} />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/deposit" element={<Deposit user={user} setUser={setUser} />} />
                    <Route path="/settings" element={<Settings user={user} setUser={setUser} />} />
                    <Route path="/transactions" element={<Transactions user={user} setUser={setUser} />} />
                    <Route path="/help" element={<Help user={user} setUser={setUser} />} />
                    <Route path="/contact" element={<Contact user={user} setUser={setUser} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy user={user} setUser={setUser} />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions user={user} setUser={setUser} />} />
                </Routes>
                <Footer />
            </Router>
        </>
    )
}

export default App