import { useState } from "react"; 
import { Link } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { doctor } from "../../assets";

const Register = () => {
    // useState 
    const [passwordView, setPasswordView] = useState(false); 
    const [confirmPasswordView, setConfirmPasswordView] = useState(false); 

    // formData
    const [formData, setFormData] = useState({
        firstName: "", 
        lastName: "", 
        email: "",
        areaCode: "+233", 
        phone: "", 
        password: "", 
        confirmPassword: "", 
        organization: "none", 
    });
    // destructure formData
    const { firstName, lastName, email, areaCode, phone, password, confirmPassword, organization } = formData; 

    // handleChange 
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev, 
            [e.target.name]: e.target.value, 
        }));
    }; 

    // handleSubmit
    const handleSubmit = (e) => {
        e.preventDefault(); 
        if(password !== confirmPassword) {
            alert("Passwords do not match"); 
        } else {
            console.log("Creating account now!"); 
        };
        console.log(firstName, lastName, email, areaCode, phone, password, confirmPassword, organization); 
    };

    // handlePasswordView
    const handlePasswordView = () => {
        setPasswordView((prev) => !prev); 
    }; 

    // handleConfirmPasswordView 
    const handleConfirmPasswordView = () => {
        setConfirmPasswordView((prev) => !prev); 
    }; 

    return (
        <section className="flex flex-row h-[calc(100vh-65px)] w-full">
            <div className="flex flex-1 flex-col">
                <div className="flex flex-col justify-center items-center max-w-[85%] m-auto">
                    <div className="mb-5">
                        <h1 className="font-cormorant text-coolBlack text-[28px] md:text-[48px] leading-[45px] text-center">Create an account</h1>
                        <p className="font-poppins text-coolBlack text-[14px] md:text-[16px] leading-[30px]">Already have an account? {" "}
                            <span className="underline underline-coolBlack"><Link to="/login">Log in</Link></span>
                        </p>
                    </div>
                    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-9">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type="text"
                                name="firstName"
                                value={firstName}
                                placeholder="First Name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-9">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type="text"
                                name="lastName"
                                value={lastName}
                                placeholder="Last Name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-9">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type="email"
                                name="email"
                                value={email}
                                placeholder="Email"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-0.5 border-b-[1.5px] border-coolBlack mb-9 max-w-[50%]">
                                <select 
                                    className="bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack" 
                                    value={areaCode}
                                    onChange={handleChange}
                                    name="areaCode"
                                    id="areaCode"
                                >
                                    <option defaultValue>+233</option>
                                    <option value="+1">+1</option>
                                    <option value="+44">+44</option>
                                </select>
                            </div>
                            <div className="flex flex-1 border-b-[1.5px] border-coolBlack mb-9 w-[50%]">
                                <input 
                                    className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    placeholder="Phone Number"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-9">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type={!passwordView ? "password" : "text"}
                                name="password"
                                value={password}
                                placeholder="Password"
                                onChange={handleChange}
                                autoComplete="on"
                            />
                            {!passwordView ? <VisibilityOffOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/> : <RemoveRedEyeOutlinedIcon onClick={handlePasswordView} id="icon" className="cursor-pointer"/>}
                        </div>
                        <div className="flex items-center border-b-[1.5px] border-coolBlack mb-9">
                            <input 
                                className="appearance-none bg-transparent border-none w-full text-coolBlack mr-3 py-1 px-1 leading-[24px] focus:outline-none font-poppins text-[14px] md:text-[16px] placeholder-coolBlack"
                                type={!confirmPasswordView ? "password" : "text"}
                                name="confirmPassword"
                                value={confirmPassword}
                                placeholder="Confirm Password"
                                onChange={handleChange}
                                autoComplete="on"
                            />
                            {!confirmPasswordView ? <VisibilityOffOutlinedIcon onClick={handleConfirmPasswordView} id="icon" className="cursor-pointer"/> : <RemoveRedEyeOutlinedIcon onClick={handleConfirmPasswordView} id="icon" className="cursor-pointer"/>}
                        </div>
                        <div className="mb-2">
                            <p className="font-poppins text-coolBlack text-[14px] md:text-[16px]">Type of organization</p>
                        </div>
                        <div>
                            <select 
                                className="p-3 rounded bg-coolBlack text-paleRobinEggBlue mb-5 w-[50%] text-center" 
                                value={organization}
                                onChange={handleChange}
                                name="organization"
                                id="organization"
                            >
                                <option defaultValue>None</option>
                                <option value="non-profit">Non-profit</option>
                                <option value="for-profit">For-profit</option>
                            </select>
                        </div>
                        <div className="flex flex-row w-full">
                            <button className="font-poppins text-[13px] bg-paleRobinEggBlue rounded w-full py-3 font-bold">Create Account</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:flex md:flex-1">
                <img src={doctor} alt="register img" className="h-full w-full object-cover"/>
            </div>
        </section>
    )
}

export default Register